<template>
<v-container class="cyan lighten-5" fluid>
  <div style="height:93vh;">
    <div class="d-flex">
      <!-- status -->
      <div class="teal darken-2 pt-1">
        <v-icon color="white">mdi-file-document-outline</v-icon>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{attrs, on}">
          <v-btn color="blue" v-bind="attrs" v-on="on" width="150" text>
            <div class="text-body-1">{{ status[dutyStatus] }}</div>
            <v-spacer></v-spacer>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>    
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in status">
            <v-btn @click="dutyStatus=i" block elevation="0" small>{{ item }}</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>      
      <v-spacer></v-spacer>
      <!-- buttons -->
      <div v-if="dutyStatus==0">
        <v-btn @click="openNewDutyDlg" class="mx-1" small color="primary">新增</v-btn>
        <v-btn @click="pauseDuty" class="mx-1" small color="primary">停用</v-btn>
      </div>
      <div v-else >
        <v-btn @click="unpauseDuty" class="mx-1" small color="primary">启用</v-btn>
        <!-- <v-btn class="mx-1" small color="primary">导出职务</v-btn> -->
      </div>
    </div>
    <!-- content -->
    <div>
      <v-card height="88vh" elevation="0">
        <v-container class="ma-3" fluid>
          <!-- filter -->
          <div class="d-flex">
            <!-- name filter -->
            <v-menu v-model="nameFilterMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs, on}">
                <v-btn v-bind="attrs" v-on="on" text small>
                  <div>名称</div>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-card width="350" class="pa-3">
                <v-text-field placeholder="请输入" dense outlined v-model="newNameFilter"></v-text-field>
                <div class="d-flex mt-n5">
                  <v-spacer></v-spacer>
                  <v-btn @click="addNameFitler" color="primary" small>确定</v-btn>
                </div>
              </v-card>
            </v-menu>
            <!-- code filter -->
            <v-menu v-model="codeFilterMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs, on}">
                <v-btn v-bind="attrs" v-on="on" text small>
                  <div>编码</div>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-card width="350" class="pa-3">
                <v-text-field placeholder="请输入" dense outlined v-model="newCodeFilter"></v-text-field>
                <div class="d-flex mt-n5">
                  <v-spacer></v-spacer>
                  <v-btn @click="addCodeFilter" color="primary" small>确定</v-btn>
                </div>
              </v-card>
            </v-menu>
            <!-- category filter -->
            <v-menu v-model="categoryFilterMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs, on}">
                <v-btn v-bind="attrs" v-on="on" text small>
                  <div>职务类别</div>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-card class="px-3 pb-2" width="250">
                <v-checkbox v-model="allCategory">
                  <template v-slot:label>
                    <div class="text-caption">全选</div>
                  </template>
                </v-checkbox>
                <v-checkbox v-for="(category, i) in categories" :key="i" :value="i"  class="mt-n5"  v-model="filters.category">
                  <template v-slot:label>
                    <div class="text-caption">{{ category }}</div>
                  </template>
                </v-checkbox>
                <div class="d-flex mt-n4">
                  <v-spacer></v-spacer>
                  <v-btn @click="categoryFilterMenu=false" color="primary" small>确定</v-btn>
                </div>
              </v-card>
            </v-menu>
          </div>
          <!-- filter cards -->
          <div class="d-flex">
            <v-chip v-if="!!filters.name" class="blue lighten-5 mx-1" small @click:close="filters.name=''" close label><div class="text-caption">名称: {{ filters.name }}</div></v-chip>
            <v-chip v-if="!!filters.code" class="blue lighten-5 mx-1" small @click:close="filters.code=''" close label ><div class="text-caption">编码: {{ filters.code }}</div></v-chip>
            <!-- format filter -->
            <div v-if="filters.name.length !== 0 || filters.code.length !== 0" @click="formatFilter" style="cursor:pointer;" class="blue--text text-caption mx-1">清除筛选条件</div>
          </div>
          <div>
            <v-data-table :headers="headers" :items="filteredDuties" show-select :single-select="false" item-key="duty_id" v-model="selected">
            </v-data-table>
          </div>
        </v-container>
      </v-card>
    </div>
  </div>
  <duty-dlg @agree="addNewDuty" v-bind:duty="dutyToEdit" ref="dutyDlg"></duty-dlg>
</v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'DutyPage',
  components: {
    DutyDlg: () => import('@/components/admin/duty/DutyDlg.vue'),
  },
  data: () => ({
    dutyStatus: 0,
    status: ['启用的职务', '停用的职务'],
    filters: {
      name: '',
      code: '',
      category: [],
    },
    newNameFilter: '',
    nameFilterMenu: null,
    newCodeFilter: '',
    codeFilterMenu: null,
    categoryFilterMenu: null,
    categories: ['空 (未填写)'],
    allCategory: false,
    newDuty: {
      duty_name: '',
      duty_code: '',
      duty_type: '',
      duty_serial_num: '',
      duty_status: 0,
      duty_description: ''
    },
    dutyToEdit: null,
    headers: [
      {
        text: '名称',
        align: 'start',
        sortable: false,
        value: 'duty_name'
      },
      {
        text: '编码',
        value: 'duty_code'
      },
      {
        text: '职务类别',
        value: 'duty_type'
      },
      {
        text: '顺序号',
        value: 'duty_serial_num'
      }
    ],
    selected: [],
  }),
  computed: {
    ...mapGetters('duty', ['duties']),
    filteredDuties: function () {
      let temp = this.duties.filter(duty => duty.duty_status == this.dutyStatus);     
      temp = !!this.filters.name ? temp.filter(duty => duty.duty_name.includes(this.filters.name)) : temp;
      temp = !!this.filters.code ? temp.filter(duty => duty.duty_code.includes(this.filters.code)) : temp;
      return temp;
    }
  },
  watch: {
    allCategory: function () {
      this.categories.map((category, i) => {
        this.filters.category.push(i);
      })
    }
  },
  methods: {
    ...mapActions('duty', ['addDuty', 'getDuties', 'updateDuty']),
    ...mapActions('app', ['setNotification']),
    addNameFitler() {
      this.filters.name = this.newNameFilter;
      this.newNameFilter = '';
      this.nameFilterMenu = false;
    },
    removeNameFilter(index) {
      this.filters.name.splice(index, 1);
    },
    formatFilter() {
      this.filters.name = '';
      this.filters.code = '';
      this.filters.category = [];
    },
    addCodeFilter() {
      this.filters.code = this.newCodeFilter;
      this.newCodeFilter = '';
      this.codeFilterMenu = false;
    },
    removeCodeFilter(index) {
      this.filters.code.splice(index, 1);
    },
    openNewDutyDlg() {
      this.dutyToEdit = Object.assign({}, this.newDuty);
      this.$refs.dutyDlg.open();
    },
    addNewDuty() {
      this.addDuty(this.dutyToEdit);
    },
    pauseDuty() {
      if(this.selected.length == 0) {
        this.setNotification({
          status: 'warning',
          text: '请至少选择一条数据'
        })
        return ;
      }
      this.selected.map(duty => {
        this.updateDuty({
          duty_id: duty.duty_id,
          duty_status: 1,
        })
      })
      this.selected = [];
    },
    unpauseDuty() {
      if(this.selected.length == 0) {
        this.setNotification({
          status: 'warning',
          text: '请至少选择一条数据'
        })
        return ;
      }
      this.selected.map(duty => {
        this.updateDuty({
          duty_id: duty.duty_id,
          duty_status: 0,
        })
      })
      this.selected = [];
    }
  },
  mounted() {
    this.getDuties();
  }
}
</script>